import React, { useState } from 'react';
import axios from 'axios';

const BeamForm = () => {
  const [cost, setCost] = useState('');
  const [uom, setUOM] = useState(''); // State for UOM field
  const [material, setMaterial] = useState('');

  const handleCostChange = (event) => {
    setCost(event.target.value);
  };

  const handleUOMChange = (event) => {
    setUOM(event.target.value);
  };

  const handleMaterialChange = (event)=>{
    setMaterial(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Send a POST request to your API endpoint with cost, quantity, and uom
      const response = await axios.post('http://127.0.0.1:5000/insert', {
        material,
        cost,
        uom, // Include UOM field in the request payload
      });

      // Handle success (e.g., show a success message)
      console.log('Beam added successfully:', response.data);

      // Clear the input fields after submission
      setCost('');
      setUOM('');
      setMaterial('');
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error('Error adding beam:', error);
    }
  };

  return (
    <div>
      <h2>Add New Beam</h2>
      <form className="form-container" onSubmit={handleSubmit}>
      <label>
          Material:
          <input
            type="text"
            value={material}
            onChange={handleMaterialChange}
            placeholder="Enter Material"
            required
          />
        </label>
        <label>
          Cost:
          <input
            type="number"
            value={cost}
            onChange={handleCostChange}
            placeholder="Enter cost"
            required
          />
        </label>

        <label>
          UOM (Unit of Measurement):
          <input
            type="text"
            value={uom}
            onChange={handleUOMChange}
            placeholder="Enter unit of measurement"
            required
          />
        </label>
        <button type="submit">Add Beam</button>
      </form>
    </div>
  );
};

export default BeamForm;
