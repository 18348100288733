import React, { useState } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import './Navbar.css'; // Import the CSS file

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="nav">
      <Link to="/" className="site-title">
        JENGA METRICS
      </Link>
      <div className="menu-icon" onClick={toggleNavbar}>
        <i className={isOpen ? 'fas fa-times' : 'fas fa-bars'}></i>
      </div>
      <ul className={isOpen ? 'nav-menu active' : 'nav-menu'}>
        <CustomLink to="/Elements">Elements</CustomLink>
        <CustomLink to="/Bq">Bills of Quantities Calculation</CustomLink>
        <CustomLink to="/Reports">Reports</CustomLink>
      </ul>
    </nav>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? 'active' : ''}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
