import React, { useState } from 'react';
import axios from 'axios';

const Columns = () => {
  const [rate, setRate] = useState('');
  const [uom, setUOM] = useState(''); // State for UOM field
  

  const handleRateChange = (event) => {
    setRate(event.target.value);
  };

  const handleUOMChange = (event) => {
    setUOM(event.target.value);
  };



  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Send a POST request to your API endpoint with Rate, and uom
      const response = await axios.post('http://127.0.0.1:5000/columns_rate', {
        rate,
        uom, 
      });

      // Handle success (e.g., show a success message)
      console.log('Column rate added successfully:', response.data);

      // Clear the input fields after submission
      setRate('');
      setUOM('');
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error('Error adding column_rate :', error);
    }
  };

  return (
    <div>
      <h2>Add New Column Rate</h2>
      <form className="form-container" onSubmit={handleSubmit}>
        <label>
          Rate:
          <input
            type="number"
            value={rate}
            onChange={handleRateChange}
            placeholder="Enter Rate"
            required
          />
        </label>

        <label>
          UOM (Unit of Measurement):
          <input
            type="text"
            value={uom}
            onChange={handleUOMChange}
            placeholder="Enter unit of measurement"
            required
          />
        </label>
        <button type="submit">Add Column Rate</button>
      </form>
    </div>
  );
};

export default Columns;
