import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Bq = () => {
  const [elements, setElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState('');
  const [rate, setRate] = useState('');
  const [quantity, setQuantity] = useState('');

  const [total, setTotal] = useState(null);

  useEffect(() => {
    const fetchElements = async () => {
      try {
        const response = await axios.get('http://127.0.0.1:5000/get-elements');
        setElements(response.data);
      } catch (error) {
        console.error('Error fetching elements:', error);
      }
    };

    fetchElements();
  }, []);

  const handleSelectChange = async (event) => {
    const elementName = event.target.value;
    setSelectedElement(elementName);

    try {
      const response = await axios.post('http://127.0.0.1:5000/get-element-details', {
        name: elementName
      });
      const { formula } = response.data;

      setQuantity(''); // Reset quantity
      setRate(''); // Reset rate
      setTotal(null); // Reset total
    } catch (error) {
      console.error('Error fetching element details:', error);
    }
  };

  const handleQuantityChange = (event) => {
    const newQuantity = event.target.value;
    setQuantity(newQuantity);
    calculateTotal(newQuantity, rate);
  };

  const handleRateChange = (event) => {
    const newRate = event.target.value;
    setRate(newRate);
    calculateTotal(quantity, newRate);
  };

  const calculateTotal = async (qty, rt) => {
    const quantityValue = parseFloat(qty);
    const rateValue = parseFloat(rt);
    if (!isNaN(quantityValue) && !isNaN(rateValue)) {
      try {
        const response = await axios.post('http://127.0.0.1:5000/calculate-amount', {
          quantity: quantityValue,
          rate: rateValue
        });
        setTotal(response.data.amount);
      } catch (error) {
        console.error('Error calculating total:', error);
        setTotal(null);
      }
    } else {
      setTotal(null);
    }
  };

  return (
    <div className="form-container">
      <label>
        Select Element:
        <select value={selectedElement} onChange={handleSelectChange}>
          <option value="" disabled>Select an option</option>
          {elements.map((element, index) => (
            <option key={index} value={element}>
              {element}
            </option>
          ))}
        </select>
      </label>
      <br />
      <label>
        Quantity:
        <input
          type="number"
          value={quantity}
          onChange={handleQuantityChange}
          placeholder="Enter quantity"
          required
        />
      </label>
      <br />
      <label>
        Rate:
        <input
          type="number"
          value={rate}
          onChange={handleRateChange}
          placeholder="Enter rate"
          required
        />
      </label>
      <br />
      {total !== null && (
        <div>
          <h3>Amount: {total}</h3>
        </div>
      )}
    </div>
  );
};

export default Bq;
