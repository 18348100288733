
import React from "react"; 
import Navbar from "./Navbar";
import Home from "./pages/Home";
import Beams from "./pages/Beams";
import Reports from "./pages/Reports";
import Users from "./pages/Users";
import "./styles.css";
import "./SideNavBar.css";
import { Route, Routes } from "react-router-dom";
import Columns from "./pages/Columns";
import Bq from "./pages/Bq";
import Elements from "./pages/Elements";


function App() {
  return (
    <>
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/users" element={<Users />} />
          <Route path="/beams" element={<Beams />} />
          <Route path="/elements" element={<Elements />}/>
          <Route path="/columns" element={<Columns />} />
          <Route path="/bq" element={<Bq />} />
          <Route path="/reports" element={<Reports />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
