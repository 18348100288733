import React, { useState } from 'react';
import axios from 'axios';

const ElementForm = () => {
  const [name, setName] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Send a POST request to your API endpoint with name and formula
      const response = await axios.post('http://127.0.0.1:5000/register-element', {
        name,
      });

      // Handle success (e.g., show a success message)
      console.log('Element registered successfully:', response.data);

      // Clear the input fields after submission
      setName('');
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error('Error registering element:', error);
    }
  };

  return (
    <div>
      <h2>Register New Element</h2>
      <form className="form-container" onSubmit={handleSubmit}>
        <label>
          Element Name:
          <input
            type="text"
            value={name}
            onChange={handleNameChange}
            placeholder="Enter element name"
            required
          />
        </label>
        
        <button type="submit">Register Element</button>
      </form>
    </div>
  );
};

export default ElementForm;
